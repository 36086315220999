import React, { useState, useRef } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from "../../atoms/Button/Button"
import { css } from "@emotion/react";
import getUrlParameter from "../../atoms/Utm/Utm";
import Text from "../../atoms/Text/Text"
// import { navigate } from "gatsby"

export default function FormularioProyectoForm({idForm, siteUrl, paginaGracias, nomProyecto}) {

  const formikRef = useRef(null);

  let utm_campaign = getUrlParameter('utm_campaign') ? getUrlParameter('utm_campaign') : "";
  let utm_content = getUrlParameter('utm_content') ? getUrlParameter('utm_content') : "";
  let utm_medium = getUrlParameter('utm_medium') ? getUrlParameter('utm_medium') : "";
  let utm_source = getUrlParameter('utm_source') ? getUrlParameter('utm_source') : "";
  let utm_term = getUrlParameter('utm_term') ? getUrlParameter('utm_term') : "";

  const formGroupCss = css`
    &.form-group{
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(15 / var(--width_base))));          
      }
    }
    
    & select{
      background-position: top calc(100vw * (calc(18 / var(--width_base)))) right calc(100vw * (calc(12 / var(--width_base))));
      @media (max-width: 767px) {
        background-position: top calc(100vw * (calc(13 / var(--width_base)))) right calc(100vw * (calc(12 / var(--width_base))));          
      }
    }
  `;


  const buttonCss = css`
    width: 100%;
    margin-top: 0;
    @media (max-width: 767px) {
      margin-top: 0;          
    }
  `;

  const formAceptCss = css`
    &.form-acept{
      margin-top: 0;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));          
      }
    }
  `;

    const mensajeEnviadoCss = css`
        margin-top: calc(100vw * (calc(16 / var(--width_base))));
        font-size: calc(100vw * (calc(16 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));
        color: #93BA1F;
        @media (max-width: 767px) {
            font-size: calc(100vw * (calc(14 / var(--width_base))));
            line-height: calc(100vw * (calc(18 / var(--width_base))));          
        }
    `;

    const mensajeErrorCss = css`
        margin-top: calc(100vw * (calc(16 / var(--width_base))));
        font-size: calc(100vw * (calc(16 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));
        color: red;
        @media (max-width: 767px) {
            font-size: calc(100vw * (calc(14 / var(--width_base))));
            line-height: calc(100vw * (calc(18 / var(--width_base))));          
        }
    `;

  const formNroDoc = css`
    display: flex;  
  `;
  
  const formNroDocLeft = css`
    width: calc(100vw * (calc(150 / var(--width_base))));
    flex-shrink: 0;
  `;

  const formNroDocRight = css`
    width: 100%;
    margin-left: calc(100vw * (calc(1 / var(--width_base))));
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;
  const boldText = css`
  font-weight: bold;
  text-decoration: underline;
`;
  const [envio, setEnvio] = useState("");

  return (
    <div>
      <Text data="INGRESA TUS DATOS" styleCss={titleCss} colorText="#8cb036" align="center"/>
      <Formik
        innerRef={formikRef}
        initialValues={{
          TipoDocumento: 'DNI',
          NroDocumento: '',
          Nombre: '',
          Apellidos: '',
          Telefono: '',
          Email: '',
          NroDormitorios: '',
          Acepto_condiciones: ''}}
        validate={values => {
          const errors = {};
          const regexNumber = /^[0-9]+$/;
          if (!values.TipoDocumento) errors.TipoDocumento = 'Campo requerido';
          if (!values.NroDocumento) errors.NroDocumento = 'Campo requerido';
          else if(!(values.NroDocumento.match(regexNumber))) errors.NroDocumento = 'Solo numeros';
          if (!values.Nombre) errors.Nombre = 'Campo requerido';
          if (!values.Apellidos) errors.Apellidos = 'Campo requerido';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!values.NroDormitorios) errors.NroDormitorios = 'Campo requerido';
          if (!values.Email) errors.Email = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) errors.Email = 'Correo Invalido';
          if (!values.Acepto_condiciones) errors.Acepto_condiciones = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{
            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/${idForm}/feedback`, {
              method: 'POST',
              body:
                'TipoDocumento=' + values.TipoDocumento +
                '&NroDocumento=' + values.NroDocumento +
                '&Nombre=' + values.Nombre +
                '&Apellidos=' + values.Apellidos +
                '&Telefono=' + values.Telefono +
                '&Email=' + values.Email +
                '&Proyecto=' + nomProyecto +
                '&NroDormitorios=' + values.NroDormitorios +
                '&UtmSource=' + utm_source +
                '&UtmMedium=' + utm_medium +
                '&UtmCampaign=' + utm_campaign +
                '&UtmTerm=' + utm_term +
                '&UtmContent=' + utm_content,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                  // // navigate(paginaGracias);
                  window.location.href = siteUrl + paginaGracias;
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group" css={css`${formGroupCss};${formNroDoc}`}>
              <div css={formNroDocLeft}>
                <Field name="TipoDocumento" as="select" className="form-control">
                  <option value="DNI">DNI</option>
                  <option value="RUC">RUC</option>
                  <option value="CÉDULA DE IDENTIDAD">CÉDULA DE IDENTIDAD</option>
                  <option value="CARNET DE EXTRANJERÍA">CARNET DE EXTRANJERÍA</option>
                  <option value="PASAPORTE">PASAPORTE</option>
                </Field>
                <ErrorMessage className="form-error" name="TipoDocumento" component="div" />
              </div>
              <div css={formNroDocRight}>
                <div css={formGroupCss}>
                  <Field className="form-control" type="text" name="NroDocumento" id="NroDocumento" placeholder="N° Documento de Identidad*"/>
                  <ErrorMessage className="form-error" name="NroDocumento" component="div" />
                </div>
              </div>
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Nombre" id="Nombre" placeholder="Nombre*"/>
              <ErrorMessage className="form-error" name="Nombre" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Apellidos" id="Apellidos" placeholder="Apellidos*"/>
              <ErrorMessage className="form-error" name="Apellidos" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Telefono" id="Telefono" placeholder="Teléfono*"/>
              <ErrorMessage className="form-error" name="Telefono" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="email" name="Email" id="Email" placeholder="Email*"/>
              <ErrorMessage className="form-error" name="Email" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="number" name="NroDormitorios" id="NroDormitorios" placeholder="Nro dormitorios*"/>
              <ErrorMessage className="form-error" name="NroDormitorios" component="div" />
            </div>
            <div className="form-group text-center">
              <label className="form-acept" css={formAceptCss} htmlFor="Acepto_condiciones">
                <Field className="accept-box" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones"/>&nbsp;&nbsp;&nbsp;He leído y acepto todos los <a css={boldText} href="/terminos-y-condiciones/" target="_blank" rel="noreferrer">términos y condiciones</a> y la <a css={boldText} href="/terminos-y-condiciones/" target="_blank" rel="noreferrer">política de privacidad</a> de la empresa INMOBILIARIA FUNDAMENTA</label>
              <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />
            </div>
            <div className="text-center" css={formGroupCss}>
              <Button typeEle="submit" styleTypeEle="submit" disabled={isSubmitting} styleEle={buttonCss}>ENVIAR COTIZACION</Button>
            </div>
            { (envio === false && envio !== "") && <div css={mensajeErrorCss}>Error en el envio</div>}
            { (envio === true && envio !== "") && <div css={mensajeEnviadoCss}>Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </div>
  );

}